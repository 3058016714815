import React, { useEffect, useRef, useState } from 'react'
import InlineBlock from './InlineBlock';
import ReactDomServer from 'react-dom/server'
import SelectionList from '../common/SelectionList';
import '../../styles/EditableContent.css'
import {INLINE_BLOCKS, BLOCK_ATTR, COMMAND_LIST, COMMAND_TYPES, BLOCK_ACTIONS} from './BuildingBlockConstants'
/*
idea:
    give every element a depth and a num to identify the object
    then when we have a range, get the startcontainer and figure out depth and num
    if we have that, save that info, after render, queryselect that element and select it

    what if i insert an element?
    what if i delete an element?

    For now:
        just save onBlurr 
*/

/* getting called when finished editing, every few seconds? get caret position before doing it */

export default function EditableContent(props) {
    const [showItemList, setShowItemList] = useState(false)
    const [currentCode, setCurrentCode] = useState("");

    const editablecontentRef = useRef()
    /*
        Disable itemList when:
            onBlurr
            onClick
            when entering space, escape
    */
     useEffect(() => {
        if(props.selected == props.idx){
            handleFocus();
        }
    }, [props.selected]) 
    const handleKeyDown = (e)=>{       
        if(showItemList){
            return;
        }
        /* on press tab or enter when showing codes, insert the top code */
        if(e.key === "Backspace"){
            if(currentCode.length > 0){
                setCurrentCode((oldCode)=>oldCode.substr(0,oldCode.length-1))
            }else if(showItemList){
                setShowItemList(false)
            }
        }
        if(e.key == "ArrowLeft"){
            clearCodes()
            let range = window.getSelection().getRangeAt(0);

            if(range.startContainer.nodeType == 3){
               let  testRange = range.cloneRange();
               
                if(testRange.startOffset == 0){

                    let spaceholder = document.createTextNode("\u200b");
                    if(testRange.endContainer.previousSibling){
                        
                        /* if the next sibling is not a textnode, handle it*/
                        if(testRange.endContainer.previousSibling.nodeType != 3){
                            if(testRange.endContainer.previousSibling.getAttribute(BLOCK_ATTR.CARET_SKIP)){
                                return;
                            }
                            if(testRange.endContainer.previousSibling.lastChild){
                               
                                if(testRange.endContainer.previousSibling.lastChild.nodeType !=3){
                                  
                                    //create textnode before                                    
                                    testRange.endContainer.previousSibling.lastChild.after(spaceholder)
                                    e.preventDefault();
                                }else{
                                    if(testRange.endContainer.previousSibling.lastChild.length == 0){
                                        testRange.endContainer.previousSibling.lastChild.insertData(0,"\u200b")
                                    }
                                    return;
                                }
                            }else{
                                console.log("sibling has no child, so insert spaceholder as new child")
                                testRange.endContainer.previousSibling.append(spaceholder)
                                //create textnode inside
                                e.preventDefault();
                            }
                        }else{
                            if(testRange.endContainer.previousSibling.length == 0){
                                testRange.endContainer.previousSibling.insertData(0,"\u200b")
                            }
                            return;
                        }
                    }else{
                       // console.log("Has NO sibling")
                        //no next sibling exist, so go to parent, and go to node after parent
                        if(testRange.endContainer.parentNode == editablecontentRef.current){
                            return;
                        }
                        //check if parent has a next sibling
                        if(testRange.endContainer.parentNode.previousSibling){
                            if(testRange.endContainer.parentNode.previousSibling.nodeType != 3){
                                testRange.endContainer.parentNode.previousSibling.after(spaceholder)

                                e.preventDefault();
                            }else{
                                if(testRange.endContainer.parentNode.previousSibling.length == 0){
                                    testRange.endContainer.parentNode.previousSibling.insertData(0,"\u200b")
                                }
                                return;
                            }
                        }else{
                            //console.log("Parent has also no next sibling, insert space after parent")
                            //create sibling text node
                            testRange.endContainer.parentNode.before(spaceholder)
                            e.preventDefault();
                        }
                    }
                    range.setStart(spaceholder,1)
                    range.setEnd(spaceholder,1)
                }
            }
            
            /*  IF i am at the last textnode of an element go to parent, select nextSibling
                    if nextSibling is an element, insert textnode before that
                    else select that textnode
                else go to closest next textnode
            */
        }
        if(e.key == "ArrowRight"){
            clearCodes()
            let range = window.getSelection().getRangeAt(0);
            if(range.startContainer.nodeType == 3){
               let  testRange = range.cloneRange();
               
                if(testRange.endOffset == testRange.startContainer.length){
                    /*
                        We are at the end of a textnode.
                        nextNode exists?
                            yes: nextNode is element?
                                yes: nextNode.firstnode is textnode?
                                    yes: default
                                    no: create textnode
                                no: default
                            no: go to parent node
                                parent.nextSibling exists?
                                    yes: nextSibling is textnode?
                                        yes: default
                                        no: create textnode
                                    no: create textnode after

                    */
                   // console.log("END")
                    let spaceholder = document.createTextNode("\u200b");
                    if(testRange.endContainer.nextSibling){
                        //console.log("Has sibling")
                        /* if the next sibling is not a textnode, handle it*/
                        if(testRange.endContainer.nextSibling.nodeType != 3){
                            if(testRange.endContainer.nextSibling.getAttribute(BLOCK_ATTR.CARET_SKIP)){
                                return;
                            }
                            //console.log("sibling is element")
                            if(testRange.endContainer.nextSibling.firstChild){
                             //   console.log("sibling has child")
                                if(testRange.endContainer.nextSibling.firstChild.nodeType !=3){
                                  //  console.log("child is not text, so insert before child")
                                    //create textnode before                                    
                                    testRange.endContainer.nextSibling.firstChild.before(spaceholder)
                                    e.preventDefault();
                                }else{
                                    if(testRange.endContainer.nextSibling.firstChild.length == 0){
                                        testRange.endContainer.nextSibling.firstChild.insertData(0,"\u200b")
                                    }
                                    return;
                                }
                            }else{
                             //   console.log("sibling has no child, so insert spaceholder as new child")
                                testRange.endContainer.nextSibling.append(spaceholder)
                                //create textnode inside
                                e.preventDefault();
                            }
                        }else{
                            if(testRange.endContainer.nextSibling.length == 0){
                                testRange.endContainer.nextSibling.insertData(0,"\u200b")
                            }
                            return;
                        }
                    }else{
                       // console.log("Has NO sibling")
                        //no next sibling exist, so go to parent, and go to node after parent
                        if(testRange.endContainer.parentNode == editablecontentRef.current){
                            return;
                        }
                        //check if parent has a next sibling
                        if(testRange.endContainer.parentNode.nextSibling){
                            if(testRange.endContainer.parentNode.nextSibling.nodeType != 3){
                                testRange.endContainer.parentNode.nextSibling.before(spaceholder)

                                e.preventDefault();
                            }else{
                                if(testRange.endContainer.parentNode.nextSibling.length == 0){
                                    testRange.endContainer.parentNode.nextSibling.insertData(0,"\u200b")
                                }
                                return;
                            }
                        }else{
                            //console.log("Parent has also no next sibling, insert space after parent")
                            //create sibling text node
                            testRange.endContainer.parentNode.after(spaceholder)
                            e.preventDefault();
                        }
                    }
                    range.setStart(spaceholder,1)
                    range.setEnd(spaceholder,1)
                }
            }
            
            /*  IF i am at the last textnode of an element go to parent, select nextSibling
                    if nextSibling is an element, insert textnode before that
                    else select that textnode
                else go to closest next textnode
            */
        }
        if(e.key == "Enter"){
            
            if(!e.shiftKey){
                console.log("EVENT ON EDITABLECONTENT",e)
                e.preventDefault();
                props.dispatchBlock({type:BLOCK_ACTIONS.ADD, payload: {blockID: 5}})
            }
        }
    }
    const handleClick = (e)=>{
        
        clearCodes();
    }
    const clearCodes = () =>{
        setShowItemList(false)
        setCurrentCode("")
    }
    const handleInput = (e)=>{
        if(e.nativeEvent.inputType != "insertText"){
            //clearCodes();
            return;
        }
        const typedChar = e.nativeEvent.data;
        if(!showItemList && COMMAND_LIST.some((x)=>x.value.startsWith(typedChar))){
            setCurrentCode(typedChar)
            setShowItemList(true)
        }else if(showItemList){
            let newCode = currentCode + typedChar;
            setCurrentCode((oldCode)=>oldCode+typedChar)
            
        }
    }
    const executeCommand = (cmd)=>{
        let selection = window.getSelection();
        let range;
        let rangeOK;
        if(selection?.rangeCount > 0){
            range = selection.getRangeAt(0)
            if(editablecontentRef.current?.contains(range.startContainer)){        
                range.setStart(range.startContainer, Math.max(range.startOffset-currentCode.length,0));
                range.extractContents();
                rangeOK = true;
            }
        }
        clearCodes();
        switch(cmd.type){
            case COMMAND_TYPES.INSERT_INLINE:
                if(!rangeOK) return;
                let placeholder = document.createElement("div")
                console.log(INLINE_BLOCKS)
                placeholder.innerHTML = ReactDomServer.renderToStaticMarkup(INLINE_BLOCKS.find((x)=>cmd.id===x.ID)?.INIT("\u200b"));
               //placeholder.innerHTML = ReactDomServer.renderToStaticMarkup(INLINE_BLOCKS.find((x)=>cmd.id===x.ID)?.INIT(""));
                range.insertNode(placeholder.firstElementChild);                    
                
                return;
            case COMMAND_TYPES.CHANGE_BLOCK:
                props.dispatchBlock({type:BLOCK_ACTIONS.CHANGE, payload: {blockID: cmd.id}})
                return;
            case COMMAND_TYPES.ADD_BLOCK:
                props.dispatchBlock({type:BLOCK_ACTIONS.ADD, payload: {blockID: cmd.id}})
            default:
                return;
        }
    }
    const save = ()=>{
        console.log("HTML",editablecontentRef.current.innerHTML)
        let inlineList = htmlToInlineElements(editablecontentRef.current);
        /* update the innerHTML with clean html code; only needed when illegal blocks are added and they dont change the state */
        if(editablecontentRef.current){
            editablecontentRef.current.innerHTML = InlineElementsToHtml(inlineList)
        }
        props.dispatchBlock({type:BLOCK_ACTIONS.UPDATE, payload: {data: inlineList}})
    }
    const handleFocus = (e)=>{
        e?.preventDefault();
        if(editablecontentRef.current){
            editablecontentRef.current.focus();
            var range = document.createRange();
            range.selectNodeContents(editablecontentRef.current);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
    return (
        <>
        <div
            ref={editablecontentRef}
            className="EditableContent"
            onKeyDown={handleKeyDown}
            contentEditable={true}
            onBlur={save} /* here we load new html in */
            onFocus={handleFocus}
            onInput={handleInput}
            onClick={handleClick}
            /* onClick={(e)=>console.log(e)} */
            dangerouslySetInnerHTML={{__html:InlineElementsToHtml(props.inlineElements)}}
        >
        
        </div>
       {showItemList&&<SelectionList onEmpty={clearCodes} filter={currentCode} items={COMMAND_LIST} onSelect={executeCommand}></SelectionList>}
        {currentCode}
        </>
    )
}

const InlineElementsToHtml = (inlineElements)=>{
    return ReactDomServer.renderToStaticMarkup(<InlineBlock inlineElements={inlineElements}></InlineBlock>)
}
const htmlToInlineElements = (element) =>{
    let block =[];
    element.childNodes.forEach((node)=>{
        if(node.nodeType == 3){ // text
            block.push(node.textContent);
        }else if(node.nodeType == 1){ //element
            let attr = node.getAttribute(BLOCK_ATTR.ID);
            if(!attr) {
                console.log("THE NODE",node)
                if(node.tagName === "BR"){
                    block.push({id:0})
                }   
                return
            };
            block.push({id:parseInt(attr), children: htmlToInlineElements(node)})
        }
    })
    return block;
}