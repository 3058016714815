import React, { useEffect, useRef, useState } from 'react'
import { IoSettingsSharp } from 'react-icons/io5'
import ColorPicker from './ColorPicker'
import ToolsMenu from './ToolsMenu'
import Color from '../../classes/color'
import Canvas2 from './Canvas2'
import Movable from '../common/Movable'
import ToolsPicker from './ToolsPicker'
import { BLOCK_ACTIONS } from '../EditableContentReworked.jsx/BuildingBlockConstants'

/*
InlineElements:
[{sourceDocument, lastChanged, createdAt, userId, colors, lineStyle}

]


*/
export default function DrawBoard({dispatchBlock, inlineElements}) {    
    const [colors, setColors] = useState([new Color(Color.TYPE.RGB, {r:0,g:112,b:189}, 1),new Color(Color.TYPE.RGB, {r:1,g:122,b:255}, 0.5)])
    const [selectedColor, setSelectedColor] = useState(0);
    const [edit, setEdit] = useState(false)
    const [erease, setErease] = useState(false)
    const [lineWidth, setlineWidth] = useState(2) 
    const [smooth, setSmooth] = useState(2)
    const [currentPage, setCurrentPage] = useState(0)
    const canvasContainer = useRef(null)
    const printFrame = useRef(null)
    const onColorChange = (newColor)=>{
        setColors((oldVal)=>{
            let newVal = [...oldVal];
            newVal[selectedColor] = newColor;
            return newVal;
        })
    }
    const onLineWidthChange = (width) =>{
        console.log(width)
        setlineWidth(width)
    }
    const onSmoothChange = (level)=>{
        setSmooth(level)
    }
    const onLinesChange = (lines)=>{
        console.log("dispatch line update")
        dispatchBlock({type: BLOCK_ACTIONS.UPDATE, payload: {data: lines.current}})
    }
    let width = 800;
    const print = ()=>{
        if(canvasContainer.current && printFrame.current){
            let childs = canvasContainer.current.children;
            let printDoc = (printFrame.current.contentWindow || printFrame.current.contentDocument);
            if (printDoc.document) printDoc = printDoc.document;
            printDoc.write('<head>');
            printDoc.write('<style>@page { margin: 0; }@media print {@page { margin: 0; }body { margin: 0cm; }}</style>')
            printDoc.write('</head><body onload="this.focus();this.print();">');
            for(let i = 0; i<childs.length; i++){
                let dataUrl = childs[i].toDataURL(); //attempt to save base64 string to server using this var  
                printDoc.write('<img width="100%" style="position:absolute;"src="' + dataUrl + '">')
            }
            printDoc.write('</body>');
            printDoc.close();
        }
    }
    return (
        <div style={{position:"relative",display: "flex", justifyContent: "center",     alignItems: "flex-start"}}>
            <div ref={canvasContainer} style={{position:"relative", boxShadow: "#00000040 0px 0px 4px", height: `${Math.round(width*99/70)}px`, width: `${width}px`, background:"white", directM: "", touchAction: edit?"pinch-zoom":undefined}}>
                <Canvas2 onChange={onLinesChange} initialLines={inlineElements} enabled={edit} erease={erease}  smooth={.3} thickness={lineWidth} color={colors[selectedColor]}  width={width} height={Math.round(width*99/70)}></Canvas2>
            </div>

            <div>
                <div style={{            
                background: "#444444",
                color: "white",
                padding: "7px",
                cursor: "pointer",
                borderRadius: "0px 6px 6px 0px"
                }} onClick={()=>setEdit((old)=>!old)}>{edit?"Lock":"Edit"}</div>
                <div style={{            
                background: "#444444",
                color: "white",
                padding: "7px",
                marginTop: "7px",
                cursor: "pointer",
                borderRadius: "0px 6px 6px 0px"
                }} onClick={()=>setErease((old)=>!old)}>{erease?"Normal":"Erase"}</div>
                <div style={{            
                background: "#444444",
                marginTop: "7px",
                color: "white",
                padding: "7px",
                cursor: "pointer",
                borderRadius: "0px 6px 6px 0px"
                }} onClick={()=>print()}>Print</div>
             </div>
             <iframe ref={printFrame} style={{display:"none"}}></iframe>
            {edit&&
                <>
                    <ColorPicker onChangeColor={onColorChange} color={colors[selectedColor]} ></ColorPicker>
                    {/* <Movable>
                    <ToolsMenu  colors={colors} onColorSelect={onColorSelect}></ToolsMenu></Movable> */}
                    <ToolsPicker color={colors[selectedColor]} onSmoothChange={onSmoothChange} onLineWidthChange={onLineWidthChange}></ToolsPicker>
                </>
            }
        </div>
    )
}
