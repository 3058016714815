/*
Desc:
    This is an inlineBlock that is used inside contentEditable.
    
    Props:
        inlineElements: array of {id, children=[inlinelements or string]} or string

*/

import React, { useState } from 'react'
import {INLINE_BLOCKS} from './BuildingBlockConstants'
export default function InlineBlock({inlineElements}) {
    return (
        <>
            {inlineElements?.map((element,key)=>{
                if(typeof(element)==="string"){
                    return <React.Fragment key={key}>{element}</React.Fragment>
                }

                let inlineElement = INLINE_BLOCKS.find((x)=>element.id===x.ID);
                if(!inlineElement?.INIT){return ""}                
                return inlineElement.INIT(
                    <InlineBlock inlineElements={element.children}></InlineBlock>,
                    key
                );
            })}            
        </>
    )
}


