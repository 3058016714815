import React, {useEffect, useRef, useState} from 'react'
import Color from '../../classes/color';
import "../../styles/ColorPicker.css"
export default function ColorPicker(props) {
    let  color = props.color;
    const getMousePos = (e) =>{
        var posx = 0;
        var posy = 0;
        if (!e)  e = window.event;
      /*   if (e.pageX || e.pageY) 	{
            posx = e.pageX;
            posy = e.pageY;
        } */
        /* else if (e.clientX || e.clientY) 	{ */
            posx = e.clientX //+ document.body.scrollLeft
             //   + document.documentElement.scrollLeft;
            posy = e.clientY// + document.body.scrollTop
                //+ document.documentElement.scrollTop;
      /*   } */
        return {x: posx, y:posy};        
    }

    const getPercentage = (start,length,pos)=>{
        return (pos-start)/(length-1);
    }
    let enableCall2 = true;
    let debounceID;
    function throtteDebounce (x){               
        clearTimeout(debounceID);
        debounceID = setTimeout(() =>x(), 10);
        if (!enableCall2) return;
        enableCall2 = false;       
        x()
        setTimeout(() => enableCall2 = true, 30);        
    }

    const onPointerDown = (e,selector)=>{
        handleMouseDown(e,selector)
         const handleMove = (e)=>{           
            throtteDebounce(()=>handleMouseDown(e,selector))         
        };
        window.addEventListener("pointermove",handleMove, true);
        window.addEventListener("pointerup", function removeMove(e)
        {
            handleMouseDown(e,selector)
            window.removeEventListener("pointermove",handleMove, true);
            window.removeEventListener("pointerup",removeMove, true)
        }, true); 
    }
    const handleMouseDown = (e,selector)=>{
        let el = selector.id.current;
        if(!el){return;}
        let rect = el.getBoundingClientRect();
        let mPos = getMousePos(e);

        if(selector.y){
            setIndicator(el,selector.y,"top","height","y",mPos,rect)
        }
        if(selector.x){
            setIndicator(el,selector.x,"left","width","x",mPos,rect)
        }          
        props.onChangeColor(color)
    }


    const setIndicator = (el,selectorXY,orientation,length,dim,pos,rect)=>{
        let old = color[selectorXY.variable];
        let value = getPercentage(rect[orientation],rect[length],pos[dim]);
        value = Math.min(Math.max(0,value),1)
        value = Math.round(value*1000)/1000;
        
        if(selectorXY.inv){
            value = 1-value;
        }
        if(old !== value){            
            let newColor;
            if(selectorXY.variable === alphaSelector.x.variable){
                newColor = new Color(Color.TYPE.HSV,color.HSV, value);
            }else{
                let newHSV = {...color.HSV};         
                newHSV[selectorXY.variable] = value;
                newColor = new Color(Color.TYPE.HSV,newHSV, color.Opacity);
            }
            color = newColor;           
        }
    }
    const blackwhiteSelectorRef = useRef()
    const alphaSelectorRef = useRef()
    const colorSelectorRef = useRef()
    const colorSelector = {x:{variable:"h",inv:false}, id: colorSelectorRef};
    const alphaSelector = {x:{variable:"alpha",inv:false}, id: alphaSelectorRef};
    const saturationValueSelector = {x: {variable:"s",inv:false}, y:{variable:"v",inv:true}, id: blackwhiteSelectorRef}
    return (
        <div id="colorPicker">
            <div className="transparentBackground"  onPointerDown={(e)=>onPointerDown(e,saturationValueSelector)}  id="blackwhiteSelector" ref={blackwhiteSelectorRef} style={{touchAction: "none"}}>
                <div className="ColorPicker_dotContainer" style={{top: (1-color.HSV.v)*100+"%", left: color.HSV.s*100+"%"}}>
                    <div className="ColorPicker_dot"></div>
                </div>
                <div className="ColorPicker_layers" style={{opacity:  color.Opacity}}>
                    <div style={{background: new Color(Color.TYPE.HSV, {...color.HSV, s:1,v:1},1)}}></div>
                    <div className="ColorPicker_whiteTransparent"></div>
                    <div className="ColorPicker_transparentBlack"></div>
                </div>
            </div>
            <div onPointerDown={(e)=>onPointerDown(e,colorSelector)} id="colorSelector" ref={colorSelectorRef} style={{touchAction: "none"}} >
                <div className="ColorPicker_sliderContainer" style={{left: color.HSV.h*100+"%"}}>
                    <div className="ColorPicker_slider" ></div>
                </div>
            </div>
            <div  className="transparentBackground" id="alphaSelectorContainer">
                <div onPointerDown={(e)=>onPointerDown(e,alphaSelector)} id="alphaSelector" ref={alphaSelectorRef} className="ColorPicker_alphaSelector" style={{touchAction: "none"}}>
                    <div className="ColorPicker_sliderContainer" style={{left: color.Opacity*100+"%"}}>
                    <div className="ColorPicker_slider" ></div>
                    </div>
                </div>
            </div>
            <div  className="transparentBackground" style={{width: "100px", height:"100px"}}>
            <div style={{width: "100px", height:"100px", background: color.toString()}}>

            </div> </div>
        </div>
    )
}
