import React, { useState } from 'react'
import { MdDehaze } from 'react-icons/md'
import { PAGE_BLOCKS } from './BuildingBlockConstants';
import "../../styles/Block.css"

export default function Block({onClick, element, dispatchBlock, idx}) {    
    const block = PAGE_BLOCKS.find((block)=>element.id==block.id);
    console.log(block)
    return (
        <div className="Block_container" onClick={()=>onClick(idx)}>
            {block.init({   ...element.props,
                            inlineElements: element.inlineElements, 
                            dispatchBlock: (data)=>{
                                data.callerID = idx;
                                dispatchBlock(data)
                            }
                        })}
            <div className="Block_burger">
                <MdDehaze></MdDehaze>
            </div>
        </div>
        
    )
}
