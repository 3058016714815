import React, { useEffect, useState } from 'react'
import "../../styles/SelectionList.css"
/*
array of
filter
{name, value, id}

*/
export default function SelectionList(props) {
    const [selected, setSelected] = useState(0)
    let list = props.items.filter((item) => {
        return item.value.toLowerCase().startsWith(props.filter.toLowerCase());
    });
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [selected, list])

    useEffect(() => {
        if(list.length == 0){
            props.onEmpty();
        }else 
        if(selected > list.length-1){
            setSelected(list.length-1)
        }
    }, [list]) 

    const handleKeyDown = (e)=>{
        console.log(e.key)
        switch (e.key) {
            case "Space":
                /*
                 check if one element fully matches, return id of that
                */
                break;
            case "Escape":
                props.onEmpty();
                break;
            case "Enter":
                e.preventDefault();
                e.stopPropagation()
                console.log("EVENT ON SELECTIONLIST",e)
                props.onSelect(list[selected]);
                break;
            case "Backspace":
                break; 
            case "ArrowUp":
                e.preventDefault();
                setSelected((old)=>old === 0 ? list.length - 1 : old - 1)
                break;
            case "Tab":  
                console.log(list.length)              
                    if(list.length == 1){                        
                        e.preventDefault();
                        props.onSelect(list[selected]);    
                        break;                
                    }
            case "ArrowDown":                
                e.preventDefault();
                setSelected((old)=>old === list.length -1 ? 0 : old + 1)
                break
            default:
                //this.setState({ command: this.state.command + e.key });
                break;
        }
    }
    return (
        <div className="SelectionList_list">    
            {list.length != 0&&list.map((item,id)=>{
                let valueMatch = item.value.substr(0,props.filter.length)
                let valueRemaining = item.value.substr(props.filter.length)
                return(
                <div 
                    key={id} 
                    className={`SelectionList_item ${id===selected?"selected":""}`}
                    onMouseDown={(e)=>{e.preventDefault();props.onSelect(item)}}                
                >
                    <div className="SelectionList_name">{item.name}</div>
                    <div className="SelectionList_value"><span class="SelectionList_match">{valueMatch}</span>{valueRemaining}</div>
                </div>)})
            }
        </div>
    )
}
