import { addDoc, getDocs, query, where } from "@firebase/firestore";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import GroupedSelectionList from "./components/common/GroupedSelectionList";
import EditableContentList from "./components/EditableContentReworked.jsx/ContentList"
import Login from "./components/login";
import PageView_sideBar from "./components/PageView_sideBar";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { database } from "./firebase";
import { usePage } from "./hooks/usePages";
import './styles/PageView.css'
function App() {
  const [currentPage, setCurrentPage] = useState(null)
  const {page, pageId} = usePage(currentPage); 

  return (
    <div className="PageView_container">
      {/* <GroupedSelectionList></GroupedSelectionList> */}
      <PageView_sideBar onChange={setCurrentPage} currentPage={currentPage}></PageView_sideBar>
      <div className="PageView_page">

      {/* Current Page {page && page.name} */}
      {/* <EditableContent edit={true} blocks={[{id: 1, content:[{id: 9, content:["hi"]}]}]}></EditableContent> */}
      {currentPage?<EditableContentList pageId={pageId} content={page? page : {data:[]}}></EditableContentList>:<h1>Welcome To This Page</h1>}
      </div>
    </div>
    );
}

export default App;
