import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdLineStyle } from 'react-icons/md';
/*
TODO:

Make different canvas layers and draw maxiumum amount of objects per layer.
Modifiyg one layer results in only rerendering that layer instead of all
lines.current[x].x.length * .thickness

*/

const Canvas2 = ({onChange, initialLines, width, height, color, enabled, erease, thickness, smooth }) => {
    const currentCanvasRef = useRef(null);
    const finalCanvasRef = useRef(null);
    const backgroundCanvasRef = useRef(null);

    const isDrawing = useRef(false)
    const isEreasing = useRef(false)
    const pos = useRef(undefined);
    const lines = useRef(initialLines);

    //const [currentLine, setCurrentLine] = useState({x:[], y:[], color: color.toString()})

    const currentLine = useRef({x:[], y:[], color: color.toString(), thickness: thickness})
    const matrix = useRef(undefined)


    function removeLine(line, id){
        // console.log([...lines.current])
        // console.log("Removeline", id)
        let thickness = line.thickness;
        line = {...lines.current[id], color:  "rgba(255,0,0,0.5)", thickness: 10}
        drawLine(line, finalCanvasRef.current.getContext('2d'));

        for(let k =0; k< line.x.length-1; k++){
            plotLineWidth(line.x[k],line.y[k],line.x[k+1],line.y[k+1],thickness,(x,y)=>{setPixel(x,y,id,true)})
        }   
    }
    function checkPixel(x,y){
        if(x<0 || x >=  width || y < 0 || y >= height)
            return false;
        x = Math.round(x);
        y = Math.round(y);
        if(matrix.current[x][y].length == 0){
            return false;
        }        
        //matrix.current[x][y].sort((a,b)=> b-a)            IDK IF I NEED THIS, REMOVED IT
        //console.log("CHECKPIXEL:",matrix.current[x][y])
        matrix.current[x][y].forEach((id)=>{
            let line = lines.current[id];
            removeLine(line,id);
            lines.current[id].removed = true;
        }) 
        return true;
    }
    function setPixel(x, y, id, remove) {
        if(x<0 || x >=  width || y < 0 || y >= height)
            return;
        if(matrix.current){
            if(remove){
                let idx = matrix.current[x][y].indexOf(id);
                if (idx > -1) {
                    matrix.current[x][y].splice(idx, 1);
                }
                return;
            }else{
                if(matrix.current[x][y].indexOf(id)==-1){             
                    matrix.current[x][y].push(id)
                }
            }
        }         
    }
 
    function plotLineWidth(x0, y0, x1, y1, thickness, callback)
    {
        thickness = Math.round(thickness/2);
        if(!x0||!y0||!y1||!y0) return;
        x0 = Math.round(x0)
        y0 = Math.round(y0)
        x1 = Math.round(x1)
        y1 = Math.round(y1)
        var dx =  Math.abs(x1-x0), sx = x0<x1 ? 1 : -1;
        var dy = -Math.abs(y1-y0), sy = y0<y1 ? 1 : -1;
        var err = dx+dy, e2;                                   /* error value e_xy */

        for(let i = -thickness; i<=thickness; i++){
            callback(x0,y0+i);
            callback(x0+i,y0);
        }
        if (x0 == x1 && y0 == y1) return;
        e2 = 2*err;
        if (e2 >= dy) { err += dy; x0 += sx; }                        /* x step */
        if (e2 <= dx) { err += dx; y0 += sy; }   
        
        for (;;){                             
                                        /* loop */
            if(dx>-dy){
                for(let i = -thickness; i<=thickness; i++){
                    callback(x0,y0+i);
                }
            }else{            
                for(let i = -thickness; i<=thickness; i++){
                    callback(x0+i,y0);
                }
            }
            
            if (x0 == x1 && y0 == y1) break;
            e2 = 2*err;
            if (e2 >= dy) { err += dy; x0 += sx; }                        /* x step */
            if (e2 <= dx) { err += dx; y0 += sy; }                        /* y step */
        }
    }
    
    function rerender(){
        //console.log("RERENDER CALLED")
        if (finalCanvasRef.current) {
            
            matrix.current = Array(width).fill().map(()=>Array(height).fill().map(()=>[]))

            let ctx = finalCanvasRef.current.getContext('2d');
            ctx.clearRect(0, 0, finalCanvasRef.current.width, finalCanvasRef.current.height);
            lines.current = lines.current.filter((x)=>!x.removed)
            onChange(lines);
            lines.current.forEach((line, id)=>{
                drawLine(line, ctx);
                for(let k =0; k< line.x.length-1; k++){
                    plotLineWidth(line.x[k],line.y[k],line.x[k+1],line.y[k+1],line.thickness,(x,y)=>{setPixel(x,y,id,false)})
                }   
            })
        }        
    }
    const handleDrawStart = (e) =>{
       // console.log(e)
        e.preventDefault();
        const posNew = getMousePos(e);
        if(posNew) {
            pos.current = posNew;

            if(e.pointerType == "touch")return;

            if(erease || e.button == 5){
                isEreasing.current = true;
            }else{
                currentLine.current = {x:[posNew.x], y:[posNew.y], color: color.toString(), thickness: thickness}                
                isDrawing.current = true;
            }
        }
    };
    const handleDraw = (e) => {
      if(e.pointerType === "touch"){
        const newPos = getMousePos(e);
        if (pos.current && newPos) {
        document.scrollingElement.scrollBy( pos.current.x-newPos.x , pos.current.y-newPos.y)
        }
        return;
    }
        if (isDrawing.current) {
            e.preventDefault();
            const newPos = getMousePos(e);
            
            if (pos.current && newPos) {
                if(Math.abs(pos.current.x - newPos.x)+Math.abs(pos.current.y - newPos.y) > 1){                        
                    newPos.x = Math.round((pos.current.x+newPos.x)/2*100)/100
                    newPos.y = Math.round((pos.current.y+newPos.y)/2*100)/100

                    currentLine.current.x.push(newPos.x)
                    currentLine.current.y.push(newPos.y)           

                    drawCurrentLine(pos.current, newPos);
                    pos.current = newPos;
                }
            }
        }else if(isEreasing.current){
            e.preventDefault();
            const newPos = getMousePos(e);
            if (pos.current) {      
                if(plotLineWidth(pos.current.x, pos.current.y, newPos.x, newPos.y,1, checkPixel)){
                    
                }
            }else{                
                if(checkPixel(newPos.x,newPos.y)){
                }
            }
            pos.current = newPos;
        }
    };

    const handleDrawEnd = (e) => {
        e.preventDefault();
        const newPos = getMousePos(e);
        if(isDrawing.current){

            let line = currentLine.current;
            line.x.push(newPos.x)
            line.y.push(newPos.y)
            line = reduceLine(line, smooth)
            
            /* Update shadow matrix */
            
           // console.log("ADDLINE",lines.current.length)
            for(let k =0; k< line.x.length-1; k++){
                plotLineWidth(line.x[k],line.y[k],line.x[k+1],line.y[k+1],line.thickness,(x,y)=>{setPixel(x,y,lines.current.length,false)})
            }   

            lines.current.push(line)
            //onChange(lines);
            drawLine(line, finalCanvasRef.current.getContext('2d'));

            if (currentCanvasRef.current) {
                let ctx = currentCanvasRef.current.getContext('2d');
                ctx.clearRect(0, 0, currentCanvasRef.current.width, currentCanvasRef.current.height);
            }
            currentLine.current = {x:[], y:[], color: color.toString(), thickness: thickness};
            isDrawing.current = false;
        }else if(isEreasing.current){
            rerender();
            isEreasing.current = false;
        }
        pos.current = undefined;
    };


    const getMousePos = (e) =>{
        if (!currentCanvasRef.current) {
            return;
        }
        var posx = 0;
        var posy = 0;
        let {left, top} = currentCanvasRef.current.getBoundingClientRect();
        if (!e)  e = window.event;
        if (e.clientX || e.clientY) 	{
            posx = e.clientX //+ document.body.scrollLeft                + document.documentElement.scrollLeft;
            posy = e.clientY //+ document.body.scrollTop                + document.documentElement.scrollTop;

        }
        return {x: posx-left, y:posy-top};        
    }
    const drawLine = (line,ctx) => {    
        if(!ctx) return;    
        setCtxStyle(ctx);
        ctx.strokeStyle = line.color;
        ctx.lineWidth = line.thickness;
        ctx.beginPath();        
        ctx.moveTo(line.x[0], line.y[0]);
        for(let i = 1; i<line.x.length;i++){
            ctx.lineTo(line.x[i-1], line.y[i-1]);
            ctx.lineTo(line.x[i], line.y[i]);
        } 
        ctx.stroke(); 
    }
    const setCtxStyle = (ctx)=>{        
        ctx.lineWidth = thickness;
        ctx.lineJoin = 'round';
        ctx.lineCap = 'round';
    }
    const drawCurrentLine = (originalMousePosition, newMousePosition) => {
        if (!currentCanvasRef.current) {
            return;
        }
        const canvas = currentCanvasRef.current;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            setCtxStyle(ctx);
            ctx.strokeStyle = color.toString(false);
            ctx.beginPath();
            ctx.moveTo(originalMousePosition.x, originalMousePosition.y);
            ctx.lineTo(newMousePosition.x, newMousePosition.y);
            ctx.stroke();
        }
    };
    useEffect(() => {
        matrix.current = Array(width).fill().map(()=>Array(height).fill().map(()=>[]))
        if(finalCanvasRef.current)
            initCanvas(finalCanvasRef.current,width,height);
            let ctx = finalCanvasRef.current.getContext('2d');
            initialLines.forEach((line, id)=>{
                drawLine(line, ctx);
                for(let k =0; k< line.x.length-1; k++){
                    plotLineWidth(line.x[k],line.y[k],line.x[k+1],line.y[k+1],line.thickness,(x,y)=>{setPixel(x,y,id,false)})
                }   
            })
        if(currentCanvasRef.current)
            initCanvas(currentCanvasRef.current,width,height);
        if(backgroundCanvasRef.current){
            initCanvas(backgroundCanvasRef.current,width,height);
            initLines(backgroundCanvasRef.current,width,height);
        }

    }, [])
    return (<>
    <canvas 
        onPointerDown={enabled?handleDrawStart:undefined} 
        onPointerMove={enabled?handleDraw:undefined} 
        onPointerUp={enabled?handleDrawEnd:undefined}  
        onPointerLeave={enabled?handleDrawEnd:undefined} 
        onContextMenu={(e)=>e.preventDefault()}
        ref={currentCanvasRef} 
        style={{opacity: color.Opacity, position: "absolute", top:"0px", left:"0px", zIndex:"22"}}
    />
    <canvas
        ref={backgroundCanvasRef}
        style={{position: "absolute", top:"0px", left:"0px"}}
    />
    <canvas
        ref={finalCanvasRef}
        style={{position: "absolute", top:"0px", left:"0px"}}
    />
    </>
    )
};
const initLines = (canvas,x,y)=>{
    let ctx = canvas.getContext("2d")
    let paddingVert = 0.0407407*y;
    let paddingHor_left =0.09285714285*x;
    let paddingHor_right = 0.047619*x

    let numLinesVert = 45;
    let lineWidthVert = (x-paddingHor_left-paddingHor_right)/numLinesVert;

    let numLinesHor = 67;
    let lineWidthHor = (y-2*paddingVert)/numLinesHor;
    ctx.strokeStyle = "gray";
    for(let i = 0; i<numLinesVert; i++){
        ctx.beginPath();
        ctx.strokeStyle = "rgb(158 188 213)";
        ctx.moveTo(i*lineWidthVert+paddingHor_left, paddingVert)
        ctx.lineTo(i*lineWidthVert+paddingHor_left, y-paddingVert)
        ctx.stroke();
    }
    for(let i = 0; i<numLinesHor; i++){
        ctx.beginPath();
        ctx.strokeStyle = "rgb(158 188 213)";
        ctx.moveTo(paddingHor_left,i*lineWidthHor+paddingVert)
        ctx.lineTo(x-paddingHor_right, i*lineWidthHor+paddingVert)
        ctx.stroke();
    }

    
    ctx.beginPath();
    ctx.strokeStyle = "rgb(128  157 198)";
    ctx.lineWidth = 1
    ctx.rect(paddingHor_left, paddingVert, (x-paddingHor_left-paddingHor_right), (y-2*paddingVert))
    ctx.stroke();
}
const initCanvas = (canvas,x,y)=>{
    canvas.style.width = x + "px";
    canvas.style.height = y + "px";
    var scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
    canvas.width = x * scale;
    canvas.height = y * scale;
    canvas.getContext("2d").scale(scale, scale);

}
const reduceLine = (line, threshold)=>{
    console.log("BEFORE SMOOTH", line.x.length)
    var indexRemove;
    if(line.x.length < 2)
        return line;
    if(line.x.length > 1500 && line.x.length <= 5000){
        threshold *= 2
    }else
    if(line.x.length > 5000){
        threshold *= 5
    }else
    if(line.x.length > 2000 && line.x.length <= 5000){
        threshold *= 10
    }
    for(let i = 1; i<line.x.length-2; i++){
        indexRemove = shouldRemoveSegment(
            threshold,
            line.x[i],  line.y[i],
            line.x[i+1],line.y[i+1],
            line.x[i+2],line.y[i+2]
        );
        if(indexRemove != -1){
            line.x.splice(i+1, 1);
            line.y.splice(i+1, 1);
            i-=1;
        }
    }
    console.log("AFTER SMOOTH", line.x.length)
    return line;
}
const shouldRemoveSegment = (threshold,x_1,y_1,x_2,y_2,x_3,y_3) =>{
    let d = x_1*(y_2-y_3)+x_2*(y_3-y_1)+x_3*(y_1-y_2);
    if(d <=  threshold && d>= -threshold){
        let middle_x = middlePointLine(x_1,x_2,x_3);
        let middle_y = middlePointLine(y_1,y_2,y_3)
        if(middle_x == -1 && middle_y == -1){
            return 1;
        }
        if(middle_x == -1 && middle_y == 1){
            return 1;
        }
        if(middle_x == 1 && middle_y == -1){
            return 1;
        }
        if(middle_x == 1 && middle_y == 1){
            return 1;
        }
    }
    return -1;
}
/* returns 1 if we can remove b */
const middlePointLine = (a,b,c)=>{
    if(a==b && a==c){
        return -1;
    }
    if(b == a || b == c){
        return 1;
    }
    if(a>b){
        return b > c ? 1/*a>b>c*/ : 2 /*a>c>b*/;
    }else{
        if(c>b){ /* c>b>a */
            return 1;
        }
        /* b>c, b>a, */
        return a < c ? 2 : 0;
    }
}
export default Canvas2;
