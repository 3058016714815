import DrawBoard from "../draw/DrawBoard"
import EditableContent from "./EditableContent"

import { v4 as uuid } from 'uuid';
export const BLOCK_ACTIONS = {
    CHANGE: "changeBlock",
    SAVE: "saveBlock",
    ADD: "addBlock",
    SAVE: "saveBlock",
    UPDATE: "updateBlock",
    REMOVE: "removeBlock",
    FOCUS: "focusBlock",
    LOAD_ALL: "LOAD_ALL"

}
export const BLOCK_ATTR = {
    ID: "inlineblock_id",
    CARET_SKIP: "inlineblock_skipcaret",
    CLASS_NAME: "className"
}
const createProps = (item,id) => {
    return  {
        key: id,
        [BLOCK_ATTR.CLASS_NAME]: item.CLASS_NAME, 
        [BLOCK_ATTR.ID]: item.ID, 
        [BLOCK_ATTR.CARET_SKIP]: item.CARET_SKIP
    }
}

export const INLINE_BLOCKS = [
    {ID: 0, CMD:"/b",CARET_SKIP:"true", NAME:"Bold", CLASS_NAME:"class0", INIT: function (children, key){return <b {...createProps(this,key)} contentEditable={false} >{children}</b>}},
    {ID: 1, CMD:"/em", NAME:"Emphasise", CLASS_NAME:"claass1", INIT: function (children, key){return <em {...createProps(this,key)}>{children}</em>}},
    {ID: 2, CMD:"$$", NAME:"Latex", CLASS_NAME:"latex", INIT: function (children, key){return <b {...createProps(this,key)}>{children}</b>}}

]

const createBlock = (id, name,init)=>{
    let block = {};
    block.key = uuid();
    block.id = id;
    block.name = name;
    block.init = init;
    return block;
}
export const PAGE_BLOCKS = [
    createBlock(0,"canvas", (props)=><DrawBoard {...props}></DrawBoard>),
    createBlock(1,"h1",(props)=><h1><EditableContent {...props} ></EditableContent></h1>),
    createBlock(2,"h2",(props)=><h2><EditableContent {...props} ></EditableContent></h2>),
    createBlock(3,"h3",(props)=><h3><EditableContent {...props} ></EditableContent></h3>),
    createBlock(4,"h4",(props)=><h4><EditableContent {...props} ></EditableContent></h4>),
    createBlock(5,"p",(props)=><div><EditableContent {...props} ></EditableContent></div>)
   
]
export const COMMAND_TYPES = {
    INSERT_INLINE: "inline",
    CHANGE_BLOCK: "turninto",
    ADD_BLOCK: "addbelow"
}
export const COMMAND_LIST = INLINE_BLOCKS.map((item)=>{
    return {
        id: item.ID, 
        value: item.CMD, 
        name: item.NAME, 
        type: COMMAND_TYPES.INSERT_INLINE}
    }).concat(
        PAGE_BLOCKS.map((item)=>{
            return {
                id: item.id, 
                value: "/t"+item.name, 
                name: "Turn into " +item.name, 
                type: COMMAND_TYPES.CHANGE_BLOCK
            }}
        ).concat(
            PAGE_BLOCKS.map((item)=>{
                return {
                    id: item.id, 
                    value: "/i"+item.name, 
                    name: "Insert "+item.name, 
                    type: COMMAND_TYPES.ADD_BLOCK
                }})
        )
    )