import React, { useState } from 'react'
import {TiDelete} from 'react-icons/ti'
import {BiRename}from 'react-icons/bi'
import { doc, updateDoc, deleteDoc } from '@firebase/firestore'
import { database } from '../firebase'
export default function SideBarItem({pageId, pageName, onClick, onDelete, selected}) {
    const [renaming, setRenaming] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [pageName2, setPageName] = useState(pageName)
    const handleKeyDown = (e)=>{
        if(e.key == "Enter"){
            handleSave(e)
            e.preventDefault();
        }
    }
    const handleSave = (e)=>{
        setPageName(e.target.value)
        const pageRef = doc(database.pages, pageId);
        // Set the "capital" field of the city 'DC'
        updateDoc(pageRef, {
            name: e.target.value
        });
        
        setRenaming(false);
    }
    const handleDelete = (e)=>{
        // remove local page ....
        onDelete(pageId)

        //remove online page
        const pageRef = doc(database.pages, pageId);
        // Set the "capital" field of the city 'DC'
        deleteDoc(pageRef);
    }
    let render;
    if(deleting){
        render = 
            <div className="PageView_sideBarItemDelete">
                <div className="PageView_sideBarItemDeleteComfirm"  onClick={handleDelete}>Delete</div>
                <div className="PageView_sideBarItemDeleteCancel"  onClick={()=>setDeleting(false)}>Cancel</div>
            </div>;
    }else{
        
        render = 
        <>        
            {!renaming&&<BiRename className="PageView_sideBarItemEditIconR PageView_sideBarItemEditIcon" onClick={()=>setRenaming(true)}></BiRename>}
            <TiDelete className="PageView_sideBarItemEditIconD PageView_sideBarItemEditIcon"  onClick={()=>setDeleting(true)}></TiDelete>
        </>
        
    }
    return (
        <div  className={selected?"selected PageView_sidebarItem":"PageView_sidebarItem"} onMouseLeave={()=>setDeleting(false)}>
            {renaming ? <div className="PageView_sidebarItemName"><input defaultValue={pageName2} onBlur={handleSave} onKeyDown={handleKeyDown} autoFocus={true} className="PageView_sidebarItemNameEdit" type="text" ></input></div>:<div className="PageView_sidebarItemName" onClick={onClick}>{pageName2}</div>}
            <div className="PageView_sidebarItemEdit">
                {render}                                
            </div>
            
        </div>
    )
}
