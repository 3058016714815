import React, { useState } from 'react'
import { useAuth } from '../context/AuthContext'

export default function Login() {
    const { currentUser, logout, signInWithGoogle } = useAuth()
    const [error, setError] = useState(false)
    return (
        <div>
            {/* User:{JSON.stringify(currentUser)} */}
            {error!=false&&<div style={{background: "#ffc1c1",
    /* width: 350px; */
    margin: "10px auto 2px",
    borderRadius: "5px",
    color: "#4e4b4b",
    padding: "10px 24px"}}>{error}</div>}
            {currentUser? <button onClick={logout}>Logout</button>:<button onClick={()=>signInWithGoogle().then(()=>{}).catch((e)=>setError("An error occured while authenticating. Try again."))}>Login</button>}
        </div>
    )
}
