import {initializeApp } from "firebase/app"
import {getAuth, signInWithPopup} from "firebase/auth"
import { getFirestore, collection, query, where, getDocs, FieldValue } from "firebase/firestore";
import "firebase/firestore"
import {getStorage} from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyA94zzprD4S_q8ifsLQSmR7-fvpcT5DDN4",
    authDomain: "pages-54376.firebaseapp.com",
    projectId: "pages-54376",
    storageBucket: "pages-54376.appspot.com",
    messagingSenderId: "643946223989",
    appId: "1:643946223989:web:9ecee62a44d580d0cfdb3c",
    measurementId: "G-2D3GREEV8L"
};

const app = initializeApp(firebaseConfig)
const firestore_db = getFirestore(app)

export const auth = getAuth(app)

export const storage = getStorage(app)

export const database = {
  pages: collection(firestore_db, "pages"),
  files: collection(firestore_db, "files"),
  formatDoc: doc => {
    return { id: doc.id, ...doc.data() }
  },
  getCurrentTimestamp: FieldValue.serverTimestamp,
}


export default app

