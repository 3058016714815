import { addDoc, getDocs, query, where } from '@firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext';
import { database } from '../firebase';
import Login from './login';
import SideBarItem from './SideBarItem';

export default function PageView_sideBar({onChange, currentPage}) {
    const [pages, setPages] = useState([])
    const { currentUser } = useAuth()
    useEffect(() => {    
      if(!currentUser){
        return;
      }
      const q = query(database.pages, where("userId", "==", currentUser.uid));
      getDocs(q).then((result)=>{
        let pagesTemp = [];
        result.forEach((x)=>pagesTemp.push({...x.data(),id:x.id}))
        console.log("RHI THIS IS RESULT", result)
        if(pagesTemp.length > 0){
            onChange(pagesTemp[0].id)
        }
        setPages(pagesTemp)
      });
    }, [currentUser])
    useEffect(() => {
        let idx = pages.findIndex((x)=>x.id==currentPage);
        if(idx == -1){
            onChange(null)
        }
    }, [pages])
  
    if(!currentUser){
      return <Login></Login>
    }
    console.log(pages)

    return (
        <div className="PageView_sidebar">        
        <Login></Login>
        {pages.map((page)=>{
            return <SideBarItem 
            onDelete={(pageId)=>{
                let idx = pages.findIndex((x)=>x.id==pageId);
                if(idx == -1){
                    return;
                }
                setPages((old)=>{
                let newPages = [...old];
                newPages.splice(idx,1); 
                return newPages
            })}}
            selected={currentPage===page.id}
            key={page.id} 
            pageId={page.id} 
            pageName={page.name} 
            onClick={()=>onChange(page.id)}/>
        })}
        <div>
          <button onClick={()=>addDoc(database.pages,{
            data:[],
            userId: currentUser.uid,
            name: "Untitled"
          }).then(r=>setPages((old)=>{
              let newPages = [...old]; 
              newPages.push({id:r.id,name:"Untitled"}); 
              return newPages
            }))
          }>Add Page</button></div>
      </div>
    )
}
