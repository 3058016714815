import React, { useReducer, useState } from 'react'
import DrawBoard from '../draw/DrawBoard'
import Block from './Block';
import EditableContent from './EditableContent';
import EditableBlock from '../../classes/EditableBlock'
import { PAGE_BLOCKS, BLOCK_ACTIONS } from './BuildingBlockConstants';

import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import { doc, updateDoc } from '@firebase/firestore';
import { database } from '../../firebase';
import {RiAddCircleFill} from "react-icons/ri"
import "../../styles/ContentList.css"
const listOfContent2 = [
    {
        id:1,
        props: "",
        key: uuid(),
        inlineElements: []},
    {
        id:0,
        props: "",
        key: uuid(),
        inlineElements:[                                
                {id:0,
                    children:["obj2",{id:1,children:["obj4"]}]
                },
                {
                  id:0,children:["obj2",{id:1,children:["obj4"]}]
                }                                
            ]
    },
  //  {id:0,props:{style: {background:"yellow"}}, children: "dada"}
]


function updateBlock(state, action) {
    console.log("GOT CALL FROM",action.callerID, action)
    function saveToFireBase(data){
        console.log(database.pages, action.payload.pageId)
        const pageRef = doc(database.pages, action.payload.pageId);

        // Set the "capital" field of the city 'DC'
        updateDoc(pageRef, {
            data: data
        });
    }
    switch (action.type) {
        case BLOCK_ACTIONS.CHANGE:{
            console.log("ACTION CHANGE")
            if(state[action.callerID].id === action.payload.blockID ){
                console.log("NO CHANGES MADE")
                return state;
            }
            let newState = [...state];
            newState[action.callerID] = {...newState[action.callerID], id: action.payload.blockID};
            saveToFireBase(newState)
            return newState;
        }
        case BLOCK_ACTIONS.ADD:{
            console.log("ACTION ADD")
            let newState = [...state.slice(0,action.callerID+1),{id: action.payload.blockID, props: "", inlineElements: [],key: uuid()},...state.slice(action.callerID+1)];
            console.log({...newState})
            saveToFireBase(newState)
            return newState;
        }
        case BLOCK_ACTIONS.UPDATE:{
            console.log("ACTION UPDATE")
            // if(JSON.stringify(action.payload.data) === JSON.stringify(state[action.callerID].inlineElements)){
            //     console.log("NO CHANGES", JSON.stringify(action.payload.data))
            //     console.log(JSON.stringify(state[action.callerID].inlineElements))
            //     return state;
            // } <- when changing inlineElement, the reference is inside the state, so checking if state is equal wont change anything

            
            let newState = [...state];
            newState[action.callerID] = {...newState[action.callerID], inlineElements: action.payload.data};

            saveToFireBase(newState)
            return newState;
        }
        case BLOCK_ACTIONS.REMOVE:
            return state;
        case  BLOCK_ACTIONS.FOCUS:
            return state;
        case BLOCK_ACTIONS.SAVE:


        case BLOCK_ACTIONS.LOAD_ALL:
            return action.payload.data;
      default:
        throw new Error();
    }
}

export default function EditableContentList(props) {
    const [selected, setSelected] = useState(-1)
    /*
        block is rendered by react. The inline elements are rendered to a static markup. idk how the katex works...
        I think i know:
            when an element is clicked, check for the id, change the props
    */

    /*
        How change in data is handled?
            we pass down the dispatch function
            in that function we can do one of the following thigs:
                - add an element
                - delete an element
                - modify an element (that included changeing the type or the data)
                - save (stores the data to firebase)

            OR
                read the html from the parent node and turn it into an itemList
                this can be very expensive on big pages

                but isnt it also expensive on big pages because we have to rerender everytime we change/add??
                
                maybe dont rerender?
                using useState hook and adding object to array does not trigger a rerender!

    */
     const [state, dispatchBlock] = useReducer(updateBlock, props.content.data) 
     useEffect(() => {
         if(props.content){
            dispatchBlock({type:BLOCK_ACTIONS.LOAD_ALL,payload:{data: props.content.data}})
         }
     }, [props.content.data])
console.log("PROPS",props.content,state)
    return (
        <div style={{
            padding: "10px"}}>
            {state.length != 0 && state.map((el,idx)=>{
                if(el == undefined){
                    return;
                }
                return <Block 
                            key={el.key}
                            idx={idx}
                            onClick={(idx)=>{console.log("CLICK HAOOED");setSelected(idx)}}
                            dispatchBlock={(data)=>{
                                console.log("THIS IS IN THE DISPATCH", props.pageId)
                                data.payload = {...data.payload,pageId: props.pageId}
                                dispatchBlock(data)
                            }}
                            element={el}
                            selected={selected}     
                        > 
                            
                        </Block>
            })}
            <div className="ContentList_add" onClick={()=>dispatchBlock({callerID: state.length, payload:{blockID: 1,pageId: props.pageId}, type: BLOCK_ACTIONS.ADD})}><RiAddCircleFill></RiAddCircleFill>Add</div>
        </div>
    )
}
//blocks are blocks that sometimes have inline stuff (only static components inside)

/* DEFAULT BLOCK IS EDITABLEcontent */
