export default class Color {
    static TYPE = {
        RGB: "rgb",
        HSV: "hsv"
    }
    _HSV = {};
    _RGB = {};
    _opacity;
    constructor(type, value, opacity){        
        this.Opacity = opacity
        switch (type) {
            case Color.TYPE.RGB:
                this.RGB = value;
                break;
            case Color.TYPE.HSV:
                this.HSV = value;
                break;
            default:
                break;
        }
    }
    get RGB(){
        return this._RGB;
    }
    get HSV(){
        return this._HSV;
    }
    set RGB(rgb){
        this._RGB = rgb;
        this._HSV = Color.RGBtoHSV(rgb);
    }
    set HSV(hsv){
        this._HSV = hsv;
        this._RGB = Color.HSVtoRGB(hsv);
    }
    set Opacity(opacity){
        this._opacity = opacity;
    }
    get Opacity(){
        return this._opacity;
    }
    toString(withOpacity=true){
        if(withOpacity)
            return `rgba(${this.RGB.r},${this.RGB.g},${this.RGB.b},${this._opacity})`;
        return `rgb(${this.RGB.r},${this.RGB.g},${this.RGB.b})`;
    }
    static HSVtoRGB = (hsv)=>{
        let {h, s, v} = hsv;
        var r, g, b, i, f, p, q, t;
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v; g = t; b = p;  break;
            case 1: r = q; g = v; b = p;  break;
            case 2: r = p; g = v; b = t;  break;
            case 3: r = p; g = q; b = v;  break;
            case 4: r = t; g = p; b = v;  break;
            case 5: r = v; g = p; b = q;  break;
            default: r = v; g = t; b = p;  break;
        }
        return {
            r: Math.round(r * 255),
            g: Math.round(g * 255),
            b: Math.round(b * 255)
        };
    }
    static RGBtoHSV (rgb) {
        let {r, g, b} = rgb;
        let rAbs, gAbs, bAbs, rr, gg, bb, h, s, v, diff, diffc;
        rAbs = r / 255;
        gAbs = g / 255;
        bAbs = b / 255;
        v = Math.max(rAbs, gAbs, bAbs);
        diff = v - Math.min(rAbs, gAbs, bAbs);
        diffc = c => (v - c) / 6 / diff + 1 / 2;
        if (diff == 0) {
            h = s = 0;
        } else {
            s = diff / v;
            rr = diffc(rAbs);
            gg = diffc(gAbs);
            bb = diffc(bAbs);    
            if (rAbs === v) {
                h = bb - gg;
            } else if (gAbs === v) {
                h = (1 / 3) + rr - bb;
            } else if (bAbs === v) {
                h = (2 / 3) + gg - rr;
            }
            if (h < 0) {
                h += 1;
            }else if (h > 1) {
                h -= 1;
            }
        }
        return {
            h: h,
            s: s,
            v: v
        };
    }
}