import React from 'react'
import "../../styles/ToolsPicker.css"
export default function ToolsPicker({onLineWidthChange, onSelectTool, color, onSmoothChange}) {
    let thickness = 0.1;
    return (
        <div style={{background: "#1b1b1b",
            padding: "10px",
            borderRadius: "4px", color: "rgb(201 201 210)", userSelect: "none"}}>
            Line Width
            <div className="ToolsPicker_sliderContainer">
                <input onChange={(e)=>onLineWidthChange(e.target.value)} type="range" min="1" max="5" defaultValue="2" className="ToolsPicker_slider"></input>
                <div style={{borderRightColor: color.toString(false)}}className="ToolsPicker_sliderBackground"></div>
            </div>
            Smoothing
            <div className="ToolsPicker_sliderContainer">
                <input onChange={(e)=>onSmoothChange(e.target.value)} type="range" min="1" max="100" defaultValue="2" className="ToolsPicker_slider"></input>
            </div>
        </div>
    )
}
