import { doc, getDoc } from "@firebase/firestore";
import { useEffect, useReducer } from "react";
import { database } from "../firebase";

const ACTIONS ={
    SELECT_PAGE: 0,
    UPDATE_PAGE: 1,
}
const ROOT_PAGE={data:[],name:"ROOT",id: null, path: []}
function reducer(state, {type, payload}){
    switch (type){
        case ACTIONS.SELECT_PAGE:
            return {
                pageId: payload.pageId,
                page: payload.page
            }
        case ACTIONS.UPDATE_PAGE:
            return {...state, page: payload.page}
        default:
            return state;
    }
}
export function usePage(pageId, page){
    const [state, dispatch] = useReducer(reducer, {page,pageId})
    useEffect(() => {
        dispatch({type: ACTIONS.SELECT_PAGE, payload: {pageId, page}})
    }, [pageId,page])

    useEffect(()=>{
        if(pageId == null){
            return dispatch({type: ACTIONS.UPDATE_PAGE,payload:{page: ROOT_PAGE}})
        }
        console.log("pageid",pageId)
        let pageDocRef = doc(database.pages, pageId);
        let pageDoc = getDoc(pageDocRef).then((x)=>{
            
            if(x.exists()){
                console.log(x)
                dispatch({type: ACTIONS.UPDATE_PAGE,payload:{page: x.data()}})
            }else{
                dispatch({type: ACTIONS.UPDATE_PAGE,payload:{page: ROOT_PAGE}})
            }
        });

    },[pageId])
    console.log("usePage",state)
    return state;
}